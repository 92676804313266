@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
  isolation: isolate;
  padding-bottom: 30vw;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  @include media-queries.for-size("medium") {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.buildingContainer {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.building {
  height: auto;
  position: absolute;
  top: 0;
  left: -80%;
  width: 180%;
  margin-top: 280px;
  
  @include media-queries.for-size("small") {
    margin-top: 520px;
  }

  @include media-queries.for-size("medium") {
    left: 0;
    width: 100%;
    margin-top: 0;
  }
}

.opportunitiesClouds {
  img {
    right: -10%;
    left: auto !important;
    max-width: 100% !important;
    opacity: 0.9;
  }
}

.capabilitiesClouds {
  img {
    right: -20%;
    left: auto !important;
    max-width: 85% !important;
    opacity: 0.9
  }
}

.h2 {
  @include typography.font-style-heading-2;
  margin-bottom: var(--spacing-5)
}

.text {
  @include typography.font-style-subhead;
}

.fogContainer {
  position: relative;
  z-index: 3;
  pointer-events: none;
}

.fog {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}