/**
 * Sizes
 *
 * Group: Settings
 */

:root {
  --size-1: 40rem; // 400px in 10px base pixel size
  --size-2: 48rem; // 480px in 10px base pixel size
  --size-3: 54rem; // 540px in 10px base pixel size
  --size-4: 67.2rem; // 672px in 10px base pixel size
  --size-5: 84rem; // 840px in 10px base pixel size
  --size-6: 98rem; // 980px in 10px base pixel size
  --size-7: 116rem; // 1160px in 10px base pixel size
  --size-8: 128rem; // 1280px in 10px base pixel size
  --size-9: 144rem; // 1440px in 10px base pixel size
}
