html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  font-size: var(--text-base-size);
  color: var(--color-text);
  overflow-x: clip; // overflow:hidden breaks postion sticky on all children
  overscroll-behavior-y: none;
}

a {
  color: inherit;
  text-decoration: none;

  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}

.ops-title {
  font-weight: var(--font-weight-3);

  span {
    font-weight: var(--font-weight-6);
  }
}

:root {
  --rt-opacity: 1 !important;
}

.ops-tooltip {
  color: var(--almost-black) !important;
  background-color: var(--color-primary) !important;
}

[id^=lottie-title-],
[id^=lottie-icon-] {
  pointer-events: all;
  pointer-events: bounding-box;
  fill: none;
  cursor: pointer;
}