@use "src/styles/media-queries";

.root {
  position: absolute;
  inset: 0;
  z-index: -1;
  pointer-events: none;
}

.clouds {
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    left: 0;
    position: absolute;
    width: 100%;

    @include media-queries.for-size("medium") {
      max-width: 80%;
    }
  }
}

.cloudsImage {
  max-width: 100%;
}
