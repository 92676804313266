/**
 * Colors
 *
 * Group: Settings
 */

:root {
  --color-primary: hsl(4, 100%, 65%); // #FF5A4E

  --color-dark: hsl(212, 86%, 14%); // #052140
  --color-medium-blue: hsl(250, 32%, 25%); // #322b54
  --color-almost-black: hsl(253, 73%, 10%); // #0F072C
  --color-almost-white: hsl(230, 60%, 98%);
  --color-light: hsla(0, 0%, 100%, 1); // #ffffff
  --color-tint-1: hsl(251, 18%, 40%);
  --color-tint-2: hsl(253, 13%, 49%);
  --color-tint-3: hsl(251, 12%, 66%);
  --color-tint-4: hsl(277, 8%, 81%);
  --color-light-blue: hsl(213, 89%, 86%); // #bdd9fb

  // Semantic colors
  --color-overlay: hsla(0, 0%, 0%, 0.5);
  --color-overlay-light: hsla(0, 0%, 100%, 0.9);

  // Alias colors
  --color-text: var(--color-dark);
  --color-background: var(--color-light);
}
