@use "src/styles/mixins/buttons";
@use "src/styles/mixins/typography";

.root {
  color: var(--color-primary);
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  text-transform: uppercase;
  @include typography.font-style-small-caps;
}

.audioButton {
  background-color: transparent;
  color: var(--color-primary);
  cursor: pointer;
  border: none;
  display: inline-flex;
  gap: var(--spacing-2);
  align-items: center;
  max-height: 50px;
  padding: 0;
  text-transform: uppercase;
  @include typography.font-style-small-caps;

  &:hover {
    color: var(--color-light);
  }
}

.play {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}

.playIcon {
  height: 50px;
  width: 50px;
}

.pauseIcon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
}

.pauseBars {
  position: absolute;
  left: 50%;
  transform: translate(-65%, -40%);
  top: 50%;
}

.percent {
  height: 48px;
  width: 48px;
  transform: rotate(-90deg);
}

.percent circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: var(--color-light);
  stroke-width: 2;
  strokelinecap: round;
}

.percent circle:last-of-type {
  stroke-dasharray: 190px;
  stroke-dashoffset: calc(190px - (131px * var(--percent)) / 100);
  stroke: var(--color-primary);
  stroke-width: 4px;
}

.audio {
  flex: 1;
}

.progress {
  flex: 1;
}

.time {
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
}

.slider {
  margin-bottom: var(--spacing-2);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-primary);
  overflow: visible;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-primary);
  overflow: visible;
  cursor: pointer;
}
