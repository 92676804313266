/**
 * Radii
 *
 * Group: Settings
 */

:root {
  --radius-base: 1rem;
  --border-radius-1: calc(0.125 * var(--radius-base));
  --border-radius-2: calc(0.25 * var(--radius-base));
  --border-radius-3: calc(0.375 * var(--radius-base));
  --border-radius-4: calc(0.5 * var(--radius-base));
  --border-radius-5: calc(0.75 * var(--radius-base));
  --border-radius-6: calc(1.5 * var(--radius-base));
  --border-radius-7: calc(2 * var(--radius-base));
  --border-radius-8: calc(2.5 * var(--radius-base));
  --border-radius-9: calc(3 * var(--radius-base));
  --border-radius-pill: 9999px;
}
