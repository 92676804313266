/**
 * Spacings
 *
 * Group: Settings
 */

:root {
  --spacing-base: 0.625rem; // 10px
  --spacing-1: calc(0.5 * var(--spacing-base)); // 5px in 10px base
  --spacing-2: calc(1 * var(--spacing-base)); // 10px in 10px base
  --spacing-3: calc(1.5 * var(--spacing-base)); // 15px in 10px base
  --spacing-4: calc(2 * var(--spacing-base)); // 20px in 10px base
  --spacing-5: calc(3 * var(--spacing-base)); // 30px in 10px base
  --spacing-6: calc(4 * var(--spacing-base)); // 40px in 10px base
  --spacing-7: calc(5 * var(--spacing-base)); // 50px in 10px base
  --spacing-8: calc(6 * var(--spacing-base)); // 60px in 10px base
  --spacing-9: calc(8 * var(--spacing-base)); // 80px in 10px base
  --spacing-10: calc(10 * var(--spacing-base)); // 100px in 10px base
  --spacing-11: calc(16 * var(--spacing-base)); // 160px in 10px base
  --spacing-12: calc(18 * var(--spacing-base)); // 180px in 10px base
  --spacing-13: calc(20 * var(--spacing-base)); // 200px in 10px base
  --spacing-14: calc(22 * var(--spacing-base)); // 220px in 10px base
}
