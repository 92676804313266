@use "src/styles/media-queries";
@use "src/styles/mixins/box";
@use "src/styles/mixins/typography";

.root {
  align-self: flex-end;
  height: 100%;
  padding: var(--spacing-4) 0 var(--spacing-8);
  
  @include media-queries.for-size("medium") {
    padding-bottom: calc(var(--spacing-8) + 80px);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin: auto;
  height: 100%;
  padding: 0 var(--spacing-2);

  @include media-queries.for-size("small") {
    padding: 0 var(--spacing-10);
  }
  
  @include media-queries.for-size("medium") {
    flex-direction: row;
    gap: var(--spacing-6);
    align-items: flex-end;
    padding: 0;
    width: 80vw;
    max-width: 90rem;
  }
}

.main,
.aside {
  @include box.glass-box-container;
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.aside {
  min-width: 315px;

  @include media-queries.for-size("medium") {
    min-width: 415px;
  }
}

.head {
  @include box.glass-box-head;
}

.content {
  @include box.glass-box-content;
}

.heading {
  @include typography.font-style-heading-4;
  color: var(--color-light);
}

.text {
  @include typography.font-style-body-lg;
  color: var(--color-light);
}

.headTitle {
  @include typography.font-style-small-caps;
  color: var(--color-tint-3);
  text-transform: uppercase;
}

.badge {
  @include typography.font-style-body-md;
  @include box.glass-box-badge;
}

.headshotWrapper {
  display: flex;
  justify-content: center;
  padding: var(--spacing-2);
  background: linear-gradient(
    360deg,
    var(--color-medium-blue) 0%,
    var(--color-dark) 100%
  );
}

.headshot {
  max-width: 150px;
}

.audioWrapper {

}

.headshotWrapper {
  background-image: url("../../../../images/headshots/headshot-bg.svg");
}

.audioHeading {
  @include typography.font-style-heading-5;
  color: var(--color-light);
}

.audioText {
  @include typography.font-style-body-md;
  color: var(--color-light);
}

.audioButton {
  display: inline-flex;
  gap: var(--spacing-2);
  align-items: center;
  color: var(--color-primary);
  text-transform: uppercase;
  @include typography.font-style-small-caps;

  &:hover {
    color: var(--color-light);
  }

  svg {
    height: 50px;
    width: 50px;
  }
}
