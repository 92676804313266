@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-4);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  color: var(--color-dark);
  transition: color 0.2s 0.3s;
  pointer-events: none;
  transition: transform 0.3s, color 0.5s;
  
  @include media-queries.for-size("medium") {
    padding: var(--spacing-6) var(--spacing-7);
  }

  & > * {
    pointer-events: auto;
  }

  &.inverse {
    color: var(--color-light);
  }

  &.hidden {
    transform: translateY(-100%);

    @include media-queries.for-size("medium") {
      transform: none;
    }
  }
}

.logo {
  color: currentColor;
  width: 100px;
}

.menu {
  align-items: center;
  background-color: var(--color-dark);
  background-image: url("../../images/MenuBG.svg");
  background-position: center;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  transform: translateY(-100%);
  transition: transform 0.5s;
  z-index: -1;
  overflow-y: scroll;
}

.menuActive {
  transform: translateY(0);
}

.menuList {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-10) var(--spacing-4);
  max-width: 850px;
  margin: 0 auto;
}

.menuItem {
  @include typography.font-style-heading-2;
  color: var(--color-light);
  counter-increment: menu-counter;
  max-width: 8em;

  &:last-child {
    max-width: 12em;
  }

  &:not(:last-child) {
    margin-bottom: var(--spacing-10);
  }

  &::before {
    @include typography.font-style-body-lg;
    color: var(--color-light-blue);
    content: "0" counter(menu-counter);
    display: block;
    margin-bottom: var(--spacing-4);
  }

  a {
    color: var(--color-tint-3);
    transition: color 0.2s;

    &:hover {
      color: var(--color-white);
    }
  }
}

.subMenu {
  margin-top: var(--spacing-6);
  margin-left: var(--spacing-6);
}
