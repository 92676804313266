@use "src/styles/media-queries";
@use "src/styles/mixins/buttons";
@use "src/styles/mixins/typography";

.root {
  aspect-ratio: 3/4;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-dark);

  @include media-queries.for-size("small") {
    aspect-ratio: 3/2;
  }

  @include media-queries.for-size("medium") {
    aspect-ratio: 16/9;
  }

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-overlay-light);
    z-index: 2;
    transition: background-color 400ms ease-in-out;

    @include media-queries.for-size("medium") {
      display: none;
    }
  }

  > * {
    height: 100%;
    width: 100%;
  }

  &.isPlaying {
    &::before {
      background-color: transparent;
    }
  }
}

.videoContainer {
  width: 100%;
  height: 100%;
}

.testimonialContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: var(--spacing-5);
  max-width: 60em;
  padding: var(--spacing-8) var(--spacing-6);
  position: absolute;
  z-index: 2;

  @include media-queries.for-size("medium") {
    padding: var(--spacing-8);
    max-width: 60%;
  }

  .isPlaying & {
    opacity: 0;
    pointer-events: none;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  opacity: 1;
  transition: opacity 400ms ease-in-out;
  pointer-events: none;
}

.posterContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 400ms ease-in-out;
  background-size: cover;
  background-position: 70% center;

  @include media-queries.for-size("medium") {
    background-position: center;
  }

  .isPlaying & {
    opacity: 0;
    pointer-events: none;
  }
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.meta {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
}

.testimonial {
  font-size: 24px;
  line-height: var(--leading-4);
  letter-spacing: var(--tracking-3);
  @include typography.font-weight(6);

  @include media-queries.for-size("large") {
    font-size: var(--font-size-7); // 40px
  }
}

.name {
  @include typography.font-style-body-md;
  font-weight: 600;
}

.position {
  @include typography.font-style-body-md;
}

.playButtonWrapper {
  background-color: transparent;
  color: var(--color-dark);
  display: inline-flex;
  cursor: pointer;
  border: none;
  gap: var(--spacing-2);
  align-items: center;
  padding: 0;
  text-transform: uppercase;
  @include typography.font-style-small-caps;
  opacity: 1;
  transition: opacity 0.3s, visibility 0.3s;

  &:hover {
    color: var(--color-primary);
  }

  &.inactive {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.button {
  height: 50px;
  width: 50px;

  @include media-queries.for-size("medium") {
    height: 80px;
    width: 80px;
  }
}
