@mixin button-reset {
  background: none;
  border-width: 0;
  padding: 0;
  font-family: inherit;
}

@mixin disabled-button {
  opacity: 0.6;
  pointer-events: none;
}

@mixin tappable-button {
  border-width: 0;
  -webkit-tap-highlight-color: transparent;

  &:not(:disabled) {
    cursor: pointer;
  }
}
