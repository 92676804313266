@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
  @include typography.font-style-body-sm;
  background-color: var(--color-dark);
  color: var(--color-light);
  padding: var(--spacing-6) var(--spacing-4);

  @include media-queries.for-size("medium") {
    padding: var(--spacing-10);
  }

  a {
    text-decoration: underline;
  }
}
