@use "src/styles/media-queries";
@use "src/styles/mixins/typography";

.root {
  & + & {
    border-top: 1px solid var(--color-tint-1);
    padding-top: var(--spacing-5);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);

  @include media-queries.for-size("medium") {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
}

.index {
  @include typography.font-style-small-caps;
  color: var(--color-primary);
}

.title {
  @include typography.font-style-heading-4;
  color: var(--color-light);
}

.bodyText {
  color: var(--color-light);
  @include typography.font-style-body-md;
}

.toggles {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.toggleWrapper {
  display: flex;
  flex-direction: column;
}

.toggleTitle {
  background-color: var(--color-medium-blue);
  border-radius: var(--border-radius-4);
  cursor: pointer;
  border: none;
  color: var(--color-light);
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-4);
  overflow: hidden;
  @include typography.font-style-body-lg;

  &.active {
    border-radius: var(--border-radius-4) var(--border-radius-4) 0 0;

    .toggleArrow {
      transform: rotate(-180deg);
    }
  }
}

.toggleText {
  border: 1px solid var(--color-medium-blue);
  background-color: var(--color-almost-black);
  color: var(--color-tint-4);
  padding: var(--spacing-4);
  border-radius: 0 0 var(--border-radius-4) var(--border-radius-4);
  overflow: hidden;
  @include typography.font-style-body-md;
}
