@mixin font-style($size, $lineHeight, $letterSpacing) {
  font-size: var(--font-size-#{$size});
  line-height: var(--leading-#{$lineHeight});
  letter-spacing: var(--tracking-#{$letterSpacing});
}

@mixin font-family($family) {
  font-family: var(--font-#{$family});
}

@mixin font-weight($weight) {
  font-weight: var(--font-weight-#{$weight});
}

@mixin font-style-display {
  @include font-style(11, 1, 2);
  @include font-weight(7);
}

@mixin font-style-heading-1 {
  @include font-style(10, 3, 3);
  @include font-weight(6);
}

@mixin font-style-heading-2 {
  @include font-style(9, 3, 3);
  @include font-weight(6);
}

@mixin font-style-heading-3 {
  @include font-style(8, 3, 3);
  @include font-weight(6);
}

@mixin font-style-heading-4 {
  @include font-style(7, 4, 3);
  @include font-weight(6);
}

@mixin font-style-heading-5 {
  @include font-style(6, 7, 4);
  @include font-weight(4);
}

@mixin font-style-heading-6 {
  @include font-style(5, 6, 4);
  @include font-weight(6);
}

@mixin font-style-subhead {
  @include font-style(5, 6, 4);
  @include font-weight(4);
}

@mixin font-style-body-lg {
  @include font-style(4, 6, 4);
  @include font-weight(4);
}

@mixin font-style-body-md {
  @include font-style(3, 7, 4);
  @include font-weight(3);
}

@mixin font-style-body-sm {
  @include font-style(2, 3, 2);
  @include font-weight(4);
}

@mixin font-style-buttons {
  @include font-style(3, 7, 7);
  @include font-weight(7);
  text-transform: uppercase;
}

@mixin font-style-large-caps {
  @include font-style(5, 1, 4);
  @include font-weight(7);
}

@mixin font-style-medium-caps {
  @include font-style(3, 3, 4);
  @include font-weight(5);
}

@mixin font-style-small-caps {
  @include font-style(1, 7, 7);
  @include font-weight(4);
}
