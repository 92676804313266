@mixin lineAnimation() {
  position: relative;

  &::before {
    background: currentColor;
    content: "";
    height: 2px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
    width: 100%;
  }

  &:hover,
  &:active {
    &::before {
      transform-origin: 0 50%;
      transform: scale3d(1, 1, 1);
    }
  }
}
