@use "src/styles/media-queries";
@use "src/styles/mixins/typography";

.root {
  align-items: center;
  display: flex;
  width: 75vw;
  margin: 10vh 0 50vh;
  
  @include media-queries.for-size("medium") {
    width: 55%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  width: 100%;

  > * {
    width: 100%;
  }

  @include media-queries.for-size("medium") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.content {
  display: grid;
  gap: var(--spacing-2);
}

.contentTitle {
  @include typography.font-style-heading-3;
}

.contentText {
  @include typography.font-style-heading-6;
}

.box {
  background-color: var(--color-light);
  border-radius: var(--border-radius-4);
  box-shadow: var(--box-shadow-1);
  padding: var(--spacing-4) var(--spacing-5) var(--spacing-5);
  display: grid;
  gap: var(--spacing-4);
  max-width: 40em;
}

.boxHeading {
  @include typography.font-style-heading-6;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-2);

  @include media-queries.for-size("small") {
    grid-template-columns: 1fr 1fr;
  }
}

.listItem {
  @include typography.font-style-body-lg;

  &::before {
    content: "\25A0";
    color: var(--color-primary);
    font-weight: bold;
    display: inline-block;
    width: 1em;
  }
}
