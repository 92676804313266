@use "src/styles/media-queries";
@use "src/styles/mixins/typography";

.root {
  background: var(--color-medium-blue);
  border-radius: var(--border-radius-3);
  position: relative;
  padding: var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.header {
  align-items: center;
  display: inline-flex;
  gap: var(--spacing-2);
}

.icon {
  color: var(--color-primary);
}

.title {
  @include typography.font-style-heading-4;
  color: var(--color-primary);
}

.list {
  @include typography.font-style-body-sm;
  color: var(--color-tint-4);
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding: var(--spacing-3) 0;

  & + & {
    border-top: 1px solid var(--color-tint-1);
  }
}

.listDescription {
  list-style-type: initial;
  margin-left: var(--spacing-4);
}
