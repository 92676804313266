@use "src/styles/mixins/animations";
@use "src/styles/mixins/buttons";
@use "src/styles/mixins/typography";

.root {
  position: fixed;
  z-index: 10;
  bottom: var(--spacing-4);
  right: var(--spacing-4);
  display: flex;
  gap: var(--spacing-4);
  align-items: center;
  justify-content: center;
}

.skip {
  @include buttons.button-reset;
  cursor: pointer;
  @include typography.font-style-body-lg;
  text-transform: uppercase;
  @include animations.lineAnimation;
}

.play, .backToTop {
  @include buttons.button-reset;
  background-color: var(--color-dark);
  color: var(--color-light);
  cursor: pointer;
  width: 52.5px;
  height: 52.5px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.inverse {
  background-color: var(--color-light);
  color: var(--color-dark);
}

.backToTop {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:active {
    opacity: 1;
  }
}
