@use "src/styles/media-queries";
@use "src/styles/mixins/buttons";
@use "src/styles/mixins/typography";

.root {
  background: var(--color-almost-white);
  position: relative;
  overflow: hidden;
  padding: 32px 20px;
  margin: 0 auto;

  @include media-queries.for-size("small") {
    padding: 32px 70px;
  }

  @include media-queries.for-size("medium") {
    padding: 50px 0 90px 20px;
  }

  @include media-queries.for-size("large") {
    padding: 50px 70px 90px;
  }
}

.slider {
  max-width: 100%;
}

.buttons {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-4);
  margin-top: 32px;

  @include media-queries.for-size("medium") {
    padding: 0 70px;
    margin-top: 50px;
  }

  @include media-queries.for-size("large") {
    padding: 0;
  }
}

.button {
  background-color: transparent;
  border: 1px solid currentColor;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  font-size: 120%;

  &:hover {
    color: var(--color-primary);
  }
}
