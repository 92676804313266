@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.fog {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: white;
  will-change: opacity;
}

.doors {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.doorLeft {
  position: absolute;
  top: 0;
  right: 100%;
  width: 50%;
  height: 100%;
  background-color: var(--color-dark);
}

.doorRight {
  position: absolute;
  top: 0;
  left: 100%;
  width: 50%;
  height: 100%;
  background-color: var(--color-dark);
}

.skyscraper {
  background-image: linear-gradient(#fff0, #fff0 50%, white 75%, white);
  padding: 80px 20px 0;
  position: relative;

  @include media-queries.for-size("small") {
    padding: 160px 70px 0;
  }
}

.logo {
  position: absolute;
  top: 20px;

  @include media-queries.for-size("small") {
    top: 40px;
  }
}

.skyscraperHeading {
  @include typography.font-style-heading-1;
  max-width: 75%;
  margin-bottom: 10px;

  @include media-queries.for-size("medium") {
    max-width: 50%;
    margin-bottom: 30px;
  }
}

.skyscraperSmallHeading {
  @include typography.font-style-heading-6;
  display: block;
}

.skyscraperText {
  @include typography.font-style-body-lg;
  margin-bottom: 30px;
  max-width: 75%;

  @include media-queries.for-size("medium") {
    max-width: 50%;
  }
}

.buildingContainer {
  left: 0;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(to right, #90acc1, #a0b6c4);
}

.building {
  position: absolute;
  top: -15vw;
  left: -10%;
  width: 120%;
  will-change: transform;
}

.cloud {
  display: block;
  position: absolute;
  height: 40vw;
  width: auto;
  top: 40vw;
  will-change: transform;

  @include media-queries.for-size("medium") {
    top: 10vw;
    left: 0;
  }
}

.clouds {
  display: none;

  @include media-queries.for-size("medium") {
    display: block;
    position: absolute;
    top: 45vw;
    width: 160%;
    left: -30%;
  }
}

.button {
  @include typography.font-family("primary");
  font-size: 14px;
  font-weight: var(--font-weight-7);
  letter-spacing: var(--tracking-5);

  align-items: center;
  background-color: var(--color-dark);
  border: 1px solid var(--color-dark);
  border-radius: var(--border-radius-2);
  color: white;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-height: 50px;
  min-width: 150px;
  padding: var(--spacing-2) var(--spacing-4);

  &:hover {
    background-color: white;
    color: var(--color-dark);
  }

  &.buttonLight {
    background-color: white;
    border-color: white;
    color: var(--color-dark);
    &:hover {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  &.buttonPrimary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-dark);
    &:hover {
      background-color: white;
      border-color: var(--color-dark);
    }
  }
}

.cdoBox {
  border-radius: var(--border-radius-4);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  min-height: 550px;
  overflow: hidden;
  padding: 60px 30px;
  position: relative;
  text-align: center;

  @include media-queries.for-size("small") {
    border-radius: 16px;
    margin-top: 120px;
    padding: 240px 120px;
  }
}

.cdoBg {
  background-blend-mode: overlay;
  background-color: #2f3b45;
  background-image: url("../../images/landing/cdo-bg.jpg");
  background-size: cover;
  background-position: center;
  inset: -10%;
  position: absolute;
  z-index: -1;
}

.cdoBoxHeadline {
  @include typography.font-style-heading-2;
  margin-bottom: 20px;

  @include media-queries.for-size("small") {
    max-width: 50rem;
  }
}

.cdoBoxText {
  @include typography.font-style-body-lg;
  margin-bottom: 20px;

  @include media-queries.for-size("small") {
    max-width: 50rem;
  }
}

.solutions {
  background-image: linear-gradient(#fff, #f7f8fd);
  padding: 70px 20px;

  @include media-queries.for-size("small") {
    padding: 150px 70px;
  }
}

.solutionsHeading {
  @include typography.font-style-heading-2;
  margin-bottom: 50px;

  @include media-queries.for-size("small") {
    max-width: 75%;
    margin-bottom: 80px;
  }
}

.solutionsList {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @include media-queries.for-size("small") {
    flex-direction: row;
  }
}

.solution {
  flex: 1;
}

.solutionIcon {
  height: 40px;
  margin-bottom: 20px;
  width: auto;
}

.solutionHeading {
  @include typography.font-style(4, 6, 4);
  @include typography.font-weight(6);
  margin-bottom: 8px;
}

.solutionText {
  @include typography.font-style(2, 7, 4);
  @include typography.font-weight(3);
  margin-bottom: 24px;
}

.solutionLink {
  @include typography.font-style-body-sm;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 4px;
}

.contact {
  padding: 70px 20px;
  position: relative;
  overflow: hidden;

  @include media-queries.for-size("small") {
    padding: 200px 70px;
  }
}

.contactBg {
  position: absolute;
  top: -15%;
  height: 130%;
  left: 0;
  width: 100%;

  background-image: url(../../images/landing/contact-bg-mobile.jpg);
  background-position: center;
  background-size: cover;

  @include media-queries.for-size("small") {
    background-image: url(../../images/landing/contact-bg-desktop.jpg);
  }
}

.contactHeading {
  @include typography.font-style-heading-2;
  margin-bottom: 40px;
  max-width: 65%;

  @include media-queries.for-size("small") {
  }
}
