@use "src/styles/mixins/typography";
@use "src/styles/media-queries";
@use "src/styles/mixins/buttons";

.root {
  @include media-queries.for-size("medium") {
    width: 55%;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 15vh;
  will-change: transform;
}

.details {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 50%;
}

.heading {
  @include typography.font-style-heading-3;
  margin-bottom: var(--spacing-8);
  max-width: 80vw;

  @include media-queries.for-size("medium") {
    max-width: none;
  }
}

.boxWrapper {
  max-width: 40rem;
  position: relative;
  z-index: -1;
}

.box {
  border-radius: var(--border-radius-4);
  background-color: var(--color-light);
  box-shadow: var(--box-shadow-1);
  position: relative;
}

.boxInner {
  border-radius: var(--border-radius-4);
  overflow: hidden;
}

.moreButton {
  @include buttons.button-reset;

  align-items: center;
  background-color: var(--color-dark);
  border-radius: 50%;
  color: var(--color-light);
  cursor: pointer;
  display: flex;
  height: 54px;
  justify-content: center;
  left: 100%;
  margin-left: var(--spacing-4);
  margin-top: -40px;
  position: absolute;
  top: 50%;
  width: 54px;
}

.moreButtonArrow {
  width: 20px;
}

.list {
  display: grid;
}

.listItem {
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  
  @include media-queries.for-size("medium") {
    flex-direction: row;
  }
}

.listItemImage {
  width: 80px;
  height: 80px;
  margin: var(--spacing-4) 0 0 var(--spacing-4);
  border-radius: var(--border-radius-4);

  @include media-queries.for-size("medium") {
    width: 30%;
    height: auto;
    margin: 0;
    border-radius: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.listItemText {
  padding: var(--spacing-3);
  flex-grow: 1;
  flex-shrink: 0;
  
  @include media-queries.for-size("medium") {
    width: 70%;
    padding: var(--spacing-4) var(--spacing-5) var(--spacing-5);
  }
}

.listTitle {
  @include typography.font-style-heading-6;
  margin-bottom: var(--spacing-2);

  @include media-queries.for-size("medium") {
    margin-bottom: var(--spacing-3);
  }
}

.listText {
  @include typography.font-style-body-md;
}

.beforeBox,
.afterBox {
  overflow: hidden;
  padding: 0 var(--spacing-6);
}

.beforeBox {
  bottom: 100%;
  height: 100px;
  position: absolute;
  width: 100%;
}

.beforeList,
.afterList {
  display: grid;
  grid-auto-rows: 1fr;
}

.beforeList {
  position: relative;
  top: 100%;
}

.beforeListItem {
  grid-column: 1;
  grid-row: 1;
  padding-bottom: var(--spacing-4);
}
.afterListItem {
  padding-top: var(--spacing-4);
  opacity: 0.1;
}

.beforeListItemBox,
.afterListItemBox {
  background-color: var(--color-light);
  border-radius: var(--border-radius-4);
  box-shadow: var(--box-shadow-1);
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-3);
}

.beforeListTitle, .afterListTitle {
  @include typography.font-style-body-lg;
}