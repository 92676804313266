/**
 * Typography
 *
 * Group: Settings
 */
@use "src/styles/media-queries";

:root {
  /* Font Faces */
  --font-primary: "sofia-pro", sans-serif;

  /* Type base */
  --text-base-size: 1rem;

  --font-size-1: calc(0.75 * var(--text-base-size)); // 12px in 16px base
  --font-size-2: calc(0.875 * var(--text-base-size)); // 14px in 16px base
  --font-size-3: calc(1 * var(--text-base-size)); // 16px in 16px base
  --font-size-4: calc(1 * var(--text-base-size)); // 16px in 16px base
  --font-size-5: calc(1.25 * var(--text-base-size)); // 20px in 16px base
  --font-size-6: calc(1.25 * var(--text-base-size)); // 20px in 16px base
  --font-size-7: calc(1.25 * var(--text-base-size)); // 20px in 16px base
  --font-size-8: calc(1.875 * var(--text-base-size)); // 30px in 16px base
  --font-size-9: calc(1.875 * var(--text-base-size)); // 30px in 16px base
  --font-size-10: calc(1.875 * var(--text-base-size)); // 30px in 16px base
  --font-size-11: calc(3.75 * var(--text-base-size)); // 60px in 16px base

  /* Font Weights */
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;
  --font-weight-9: 900;

  /* Line heights */
  --leading-1: 1;
  --leading-2: 1.05;
  --leading-3: 1.1;
  --leading-4: 1.15;
  --leading-5: 1.2;
  --leading-6: 1.25;
  --leading-7: 1.3;
  --leading-8: 1.35;
  --leading-9: 1.4;

  /* Letter Spacings */
  --tracking-1: -0.05em;
  --tracking-2: -0.04em;
  --tracking-3: -0.03em;
  --tracking-4: -0.02em;
  --tracking-5: 0.02em;
  --tracking-6: 0.04em;
  --tracking-7: 0.05em;

  @include media-queries.for-size("small") {
    --font-size-1: calc(1 * var(--text-base-size)); // 16px in 16px base
    --font-size-2: calc(1.125 * var(--text-base-size)); // 18px in 16px base
    --font-size-3: calc(1.25 * var(--text-base-size)); // 20px in 16px base
    --font-size-4: calc(1.437 * var(--text-base-size)); // 23px in 16px base
    --font-size-5: calc(1.875 * var(--text-base-size)); // 30px in 16px base
    --font-size-6: calc(2.25 * var(--text-base-size)); // 36px in 16px base
    --font-size-7: calc(2.5 * var(--text-base-size)); // 40px in 16px base
    --font-size-8: calc(3 * var(--text-base-size)); // 48px in 16px base
    --font-size-9: calc(3.5 * var(--text-base-size)); // 56px in 16px base
    --font-size-10: calc(4 * var(--text-base-size)); // 64px in 16px base  
    --font-size-11: calc(7.5 * var(--text-base-size)); // 120px in 16px base
  }

  @media(max-width: 399px) {
    // fix for very small viewports
    --font-size-11: calc(3.25 * var(--text-base-size));
  }
}
