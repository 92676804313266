@use "src/styles/mixins/box";
@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
  max-width: 74em;
  @include box.glass-box-container;
  margin: 0 var(--spacing-2) var(--spacing-8);
  
  @include media-queries.for-size("small") {
    margin: 0 var(--spacing-10) var(--spacing-8);
  }

  @include media-queries.for-size("medium") {
    margin: 0;
  }
}

.head {
  @include box.glass-box-head;
}

.content {
  @include box.glass-box-content;
  display: flex;
  gap: var(--spacing-3);
  flex-direction: row;
}

.icon {
  color: var(--color-tint-3);
  min-width: 40px;
}

.text {
  @include typography.font-style-heading-5;
  color: var(--color-light);
}

.headTitle {
  @include typography.font-style-small-caps;
  color: var(--color-tint-3);
  text-transform: uppercase;
}

.badge {
  @include typography.font-style-body-md;
  @include box.glass-box-badge;
}
