@use "src/styles/media-queries";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-dark);
}

.lottieContainer {
  pointer-events: none;
  aspect-ratio: 1/1;
  height: 80vh;
  width: 120vw;
    
  @include media-queries.for-size("medium") {
    width: auto;
    aspect-ratio: 2/1;
  }
}
