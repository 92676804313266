@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
  position: fixed;
  height: 100vh;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  transition: transform 200ms ease-in-out;
  z-index: 12;
  
  &.open {
    transform: translateY(0);
  }

  @include media-queries.for-size("medium") {
    z-index: 10;
    padding-top: 6rem;
  }
}

.container {
  max-width: 90rem;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  > * {
    height: 100%;
  }
}

.close {
  color: var(--color-light);
  cursor: pointer;
  height: auto;
  position: absolute;
  right: var(--spacing-4);
  top: var(--spacing-4);
  z-index: 2;

  &:hover {
    color: var(--color-primary);
  }
}

.sidebar {
  background: rgba(50, 43, 84, 0.7);
  backdrop-filter: blur(2rem);
  flex-basis: 330px;
  border-radius: var(--border-radius-6) 0 0 0;
  overflow: hidden;
  padding: var(--spacing-4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main {
  background: linear-gradient(
    360deg,
    rgba(15, 7, 44, 0.48) 3.8%,
    rgba(21, 10, 63, 0.48) 100%
  );
  backdrop-filter: blur(2rem);
  flex: 1;
  padding: var(--spacing-4);
  overscroll-behavior: none;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  
  @include media-queries.for-size("small") {
    padding: var(--spacing-8) var(--spacing-10);
  }
  
  @include media-queries.for-size("medium") {
    border-radius: 0 var(--border-radius-6) 0 0;
    padding: var(--spacing-6);
  }
}

.menuNav {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.menuItem {
  cursor: pointer;
  border-radius: var(--border-radius-3);
  align-items: center;
  display: inline-flex;
  gap: var(--spacing-3);
  color: var(--color-tint-2);
  padding: var(--spacing-2);
  width: 100%;
  @include typography.font-style-body-lg;

  &:hover,
  &.active {
    background-color: var(--color-primary);
    color: var(--color-dark);
  }
}

.menuIcon,
.menuText {
  pointer-events: none;
}

.mainHead {
  border-bottom: 1px solid var(--color-tint-1);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding-bottom: var(--spacing-6);
  margin-bottom: var(--spacing-6);
}

.eyebrow {
  @include typography.font-style-small-caps;
  color: var(--color-tint-3);
  text-transform: uppercase;
}

.heading {
  @include typography.font-style-heading-4;
  color: var(--color-light);
}

.iconBoxes {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  
  @include media-queries.for-size("medium") {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.iconBoxesOddGrid {
  > :last-child {
    grid-column: 1 / -1;
  }
}

.headshots {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);

  @include media-queries.for-size("medium") {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.products {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);

  @include media-queries.for-size("medium") {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    > :nth-child(1),
    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4) {
      grid-column: span 3;
    }

    > :nth-child(5) {
      grid-column: span 6;
    }
  }

  @include media-queries.for-size("large") {
    > :nth-child(1),
    > :nth-child(2),
    > :nth-child(3) {
      grid-column: span 2;
    }

    > :nth-child(4),
    > :nth-child(5) {
      grid-column: span 3;
    }
  }
}

.report {
  background-color: var(--color-dark);
  border-radius: var(--border-radius-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  padding: var(--spacing-5);
}

.reportTitle {
  @include typography.font-style-body-lg;
  color: var(--color-light);
}

.reportText {
  @include typography.font-style-small-caps;
  color: var(--color-tint-4);
}

.reportPdf {
  display: inline-flex;
  gap: var(--spacing-2);
  align-items: center;
  color: var(--color-primary);
  @include typography.font-style-small-caps;

  &:hover {
    color: var(--color-light);
  }
}

.solutionItems {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--spacing-6);
}
