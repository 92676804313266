@use "src/styles/media-queries";
@use "src/styles/mixins/box";
@use "src/styles/mixins/typography";

.root {
  align-self: flex-end;
  padding: var(--spacing-4) 0 calc(var(--spacing-8) + 80px);
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin: auto;
  max-width: 66em;
  height: 100%;

  @include media-queries.for-size("medium") {
    flex-direction: row;
    gap: var(--spacing-6);
    align-items: flex-end;
  }
}

.main,
.aside {
  @include box.glass-box-container;
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.head {
  @include box.glass-box-head;
}

.content {
  @include box.glass-box-content;
}

.aside {
  min-width: 315px;

  @include media-queries.for-size("medium") {
    min-width: 415px;
  }
}

.heading {
  @include typography.font-style-heading-4;
  color: var(--color-light);
}

.text {
  @include typography.font-style-body-lg;
  color: var(--color-light);
}

.pdfHeading {
  @include typography.font-style-heading-5;
  color: var(--color-light);
}

.pdfText {
  @include typography.font-style-body-md;
  color: var(--color-light);
}

.pdfLink {
  display: inline-flex;
  gap: var(--spacing-2);
  align-items: center;
  color: var(--color-primary);
  text-transform: uppercase;
  @include typography.font-style-small-caps;

  &:hover {
    color: var(--color-light);
  }
}

.headTitle {
  @include typography.font-style-small-caps;
  color: var(--color-tint-3);
  text-transform: uppercase;
}

.badge {
  @include typography.font-style-small-caps;
  @include box.glass-box-badge;
}
