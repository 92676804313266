@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
}

.intro {
  align-items: center;
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  padding: 0 10%;
}

.introWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  align-items: center;
  max-width: 50em;
  text-align: center;
}

.heading {
  @include typography.font-style-display;
  color: var(--color-light);
}

.subheading {
  @include typography.font-style-subhead;
  color: var(--color-light);
}

.button {
  @include typography.font-family("primary");
  @include typography.font-style(3, 7, 5);
  @include typography.font-weight(6);

  border: none;
  border-radius: var(--border-radius-2);
  cursor: pointer;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: var(--spacing-4) var(--spacing-6);
  color: var(--color-text);

  &:hover {
    background-color: white;
    border-color: var(--color-dark);
  }
}
