@use "src/styles/media-queries";
@use "src/styles/mixins/typography";

.root {
  background: var(--color-medium-blue);
  border-radius: var(--border-radius-3);
  position: relative;
  padding: var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  @include media-queries.for-size("medium") {
    padding: var(--spacing-6);
  }
}

.icon {
  width: 25px;
  height: 25px;
  color: var(--color-light-blue);

  @include media-queries.for-size("medium") {
    width: fit-content;
    height: fit-content;
  }
}

.title {
  @include typography.font-style-heading-5;
  color: var(--color-light);
}

.bodyText {
  color: var(--color-light);
  @include typography.font-style-body-lg;

  sup {
    color: var(--color-primary);
  }

  a {
    cursor: pointer;
  }
}
