@use "src/styles/media-queries";
@use "src/styles/mixins/typography";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.imageWrapper {
  border-radius: var(--border-radius-4);
  overflow: hidden;
  max-width: 250px;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include media-queries.for-size("medium") {
    max-width: none
  }
}

.eyebrow {
  @include typography.font-style-small-caps;
  color: var(--color-primary);
}

.position {
  @include typography.font-style-heading-6;
  color: var(--color-light);
}

.list {
  @include typography.font-style-body-lg;
  color: var(--color-tint-4);
}

.listItem {
  padding: var(--spacing-3) 0;

  & + & {
    border-top: 1px solid var(--color-tint-1);
  }
}
