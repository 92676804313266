@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
	display: flex;
	align-items: flex-start;
	height: 100vh;
	padding-top: 80px;
	
	@include media-queries.for-size("small") {
		padding-top: 120px;
	}
	
  @include media-queries.for-size("medium") {
		align-items: center;
		padding-top: 0;
	}
}

.heading {
  @include typography.font-style-display;
	width: min-content;
}

.smallHeading {
	@include typography.font-style-heading-4;
	display: block;
}