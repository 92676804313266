@use "src/styles/mixins/buttons";

.root {
  @include buttons.button-reset;
  cursor: pointer;
  color: currentColor;
}

.line {
  background-color: currentColor;
  height: 4px;
  margin: 8px 0;
  transition: transform 0.5s, opacity 0.5s;
  width: 40px;

  .active & {
    background-color: var(--color-light);
    transition: transform 0.5s, opacity 0.1s;

    &:first-child {
      transform: translateY(11px) rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:last-child {
      transform: translateY(-12px) rotate(-45deg);
    }
  }
}
