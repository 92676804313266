@use "src/styles/mixins/box";
@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.root {
  align-self: flex-end;
  padding: 0 var(--spacing-2);
  height: 100%;
  display: flex;
  align-items: flex-end;

  @include media-queries.for-size("small") {
    padding: 0 var(--spacing-10);
  }

  @include media-queries.for-size("medium") {
    padding: var(--spacing-4) 0 calc(var(--spacing-8) + 80px);
  }
}

.main {
  max-width: 74em;
  @include box.glass-box-container;
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.head {
  @include box.glass-box-head;
  justify-content: flex-end;
}

.headTitle {
  @include typography.font-style-small-caps;
  color: var(--color-tint-3);
  text-transform: uppercase;
}

.content {
  @include box.glass-box-content;
}

.heading {
  @include typography.font-style-heading-4;
  color: var(--color-light);
}

.bodyText {
  @include typography.font-style-body-lg;
  color: var(--color-light);
}

.badge {
  @include typography.font-style-body-md;
  @include box.glass-box-badge;
}

.solutionsBoxes {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  &.two {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-queries.for-size("medium") {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.solutionsBox {
  background-color: rgba(91, 84, 121, 0.35);
  border-radius: var(--border-radius-4);
  color: var(--color-tint-4);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-4);
  position: relative;

  @include media-queries.for-size("medium") {
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-8);

    &:hover,
    &.active {
      color: var(--color-dark);
      background-color: var(--color-primary);

      .solutionsBoxTitle,
      .solutionsBoxArrow {
        color: var(--color-dark);

        &.normal {
          display: none;
        }

        &.hover {
          display: inline-flex;
        }
      }
    }
  }
}

.solutionsBoxIcon {
  width: 25px;
  height: 25px;
  margin-left: auto;
  margin-right: 0;

  @include media-queries.for-size("medium") {
    width: fit-content;
    height: fit-content;
  }
}

.solutionsBoxContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  margin: var(--spacing-2) 0;

  @include media-queries.for-size("medium") {
    margin: var(--spacing-4) 0 var(--spacing-6);
  }
}

.solutionsBoxTitle {
  @include typography.font-style-heading-6;
  color: var(--color-primary);
}

.solutionsBoxText {
  @include typography.font-style-body-md;
}

.solutionsBoxArrow {
  color: var(--color-primary);
  width: 15px;

  @include media-queries.for-size("medium") {
    width: fit-content;
  }
}

.solutionsBoxArrow {
  &.hover {
    display: none;
  }
}

.solutionsBoxArrowWrapper {
  @include media-queries.for-size("medium") {
    bottom: var(--spacing-4);
    position: absolute;
  }
}
