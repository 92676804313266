@use "src/styles/media-queries";

@mixin glass-box-container {
  background: linear-gradient(
    180deg,
    rgba(15, 6, 49, 0.45) 50%,
    rgba(9, 5, 34, 0.6) 100%
  );
  backdrop-filter: blur(2rem);
  border-radius: var(--border-radius-5);
  border: 1px solid var(--color-tint-3);
  overflow: hidden;

  @include media-queries.for-size("medium") {
    border-radius: var(--border-radius-6);
  }
}

@mixin glass-box-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-2) var(--spacing-3);

  @include media-queries.for-size("medium") {
    padding: var(--spacing-4);
  }
}

@mixin glass-box-content {
  border-top: 1px solid var(--color-tint-3);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  padding: var(--spacing-3);

  @include media-queries.for-size("medium") {
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-5);
  }
}

@mixin glass-box-badge {
  background-color: var(--color-tint-3);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-1);
  color: var(--color-almost-black);
  padding: var(--spacing-1);
  font-weight: var(--font-weight-5);

  @include media-queries.for-size("medium") {
    padding: var(--spacing-2);
  }
}
