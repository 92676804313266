@use "src/styles/mixins/typography";
@use "src/styles/media-queries";
@use "src/styles/mixins/buttons";
@use "sass:math";

.root {
  position: relative;
  height: 100vh;
  overflow: clip;
  z-index: 2;

  &.active {
    height: auto;
  }
}

.intro {
  align-items: center;
  color: var(--color-light);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  gap: var(--spacing-8);
  margin: auto;
  position: absolute;
  z-index: 2;
  width: 100%;
  transition: visibility 1.2s;

  .active & {
    visibility: hidden;
  }
}

.doorLeft,
.doorRight {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
  transition: transform 1.2s;
}

.doorImageLeft,
.doorImageRight {
  position: absolute;
  width: 200%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  transition: transform 1.2s;
}

.doorLeft {
  left: 0;

  &.open {
    transform: translateX(-100%);
  }
}

.doorImageLeft {
  left: 0;
  background-image: url("../../images/lobby.jpg");

  .open & {
    transform: translateX(50%);
  }
}

.doorRight {
  right: 0;

  &.open {
    transform: translateX(100%);
  }
}

.doorImageRight {
  background-image: url("../../images/lobby.jpg");
  right: 0;

  .open & {
    transform: translateX(-50%);
  }
}

.introInner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  max-width: 66em;
  align-items: center;

  transition: opacity 0.3s ease, transform 0.3s ease-in;
  &.open {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.title {
  @include typography.font-style-heading-2;
}

.subhead {
  @include typography.font-style-heading-4;
  font-weight: 500;
}

.button {
  @include typography.font-family("primary");
  @include typography.font-style(3, 7, 5);
  @include typography.font-weight(6);

  border: none;
  border-radius: var(--border-radius-2);
  cursor: pointer;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: var(--spacing-4) var(--spacing-6);
  color: var(--color-text);

  &:hover {
    background-color: white;
    border-color: var(--color-dark);
  }
}

.opsWrapper {
  position: relative;
  z-index: 1;
}

.productNavContainer {
  position: absolute;
  left: calc(3.75vw - 6px);
  top: 0;
  height: 100%;
  pointer-events: none;
  
  @include media-queries.for-size("medium") {
    left: 30px;
  }
}

.productNavInner {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

.productNav {
  z-index: 10;
  pointer-events: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s;
  pointer-events: auto;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.productNavItem {
  @include buttons.button-reset;
  @include typography.font-style-body-sm;
  height: 20px;
  position: relative;
  color: var(--color-tint-3);
  transition: color 0.4s ease;
  vertical-align: middle;
  cursor: pointer;
  
  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: currentColor;
  }

  &.active {
    color: var(--color-light);
  }
  
  @include media-queries.for-size("medium") {
    height: 30px;

    &::before {
      width: 30px;
      height: 10px;
      border-radius: 4px;
      transition: transform 0.3s;
      position: absolute;
      left: 5px;
      top: 10px;
    }
  
    > span {
      display: inline-block;
      padding-left: 45px;
      opacity: 0;
      width: 0;
  
      transition: opacity 0.5s ease, width 0s 0.5s;
  
      .productNav:hover &,
      .productNav.open & {
        opacity: 1;
        width: auto;
  
        transition: opacity 0.5s ease, width 0s;
      }
    }
  
    &.active {
      color: var(--color-tint-3);

      &::before {
        border-radius: #{math.div(4px, 1.2)} / #{math.div(4px, 3)};
        transform: scale(1.2, 3);
      }
    }
  
    &:hover {
      color: var(--color-tint-4);
    }
  }
}

.clouds {
  z-index: 1;

  img {
    max-width: 100%;
    top: -25%;

    @include media-queries.for-size("small") {
      top: -40%;
    }

    @include media-queries.for-size("medium") {
      top: -80%;
    }

    @include media-queries.for-size("large") {
      top: -90%;
    }
  }
}
