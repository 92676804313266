@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

.scrollSectionOuter {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-dark);
  position: relative;
}

.scrollWrapper {
  margin-bottom: 5px;
}

.scrollSectionInner {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  
  .scrollSectionOuter.active & {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
}

.scrollSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: scale(0.85);
  border-radius: 20px;
  transition: transform 0.7s ease, border-radius 0.7s ease;

  .bgInner {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.15);
    transition: transform 0.7s ease;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .active & {
    transform: scale(1);
    border-radius: 0;

    .bgInner {
      transform: scale(1);
    }
  }
}

.continue {
  color: var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > :nth-child(1) {
    @include typography.font-style-subhead;
  }
  > :nth-child(2) {
    @include typography.font-style-heading-2;
  }
}

.nextIcon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--color-light);
  margin-top: var(--spacing-5);
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.progressBar {
  position: absolute;
  bottom: var(--spacing-8);
  width: 100%;
  padding: 0 var(--spacing-4);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.7s ease;

  .active & {
    opacity: 1;
    visibility: visible;
  }

  @include media-queries.for-size("medium") {
    padding: 0 var(--spacing-10);
  }
}
