@use "src/styles/mixins/box";
@use "src/styles/mixins/typography";
@use "src/styles/media-queries";

:root {
  --progress-bar-height: 2px;
}

.container {
  display: none;
  
  @include media-queries.for-size("medium") {
    align-items: center;
    display: flex;
    gap: var(--spacing-4);
  }
}

.progressContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 100%;
  width: 100%;

  &::before {
    content: ""; /* Mandatory with ::before */
    background-color: var(--color-tint-1);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: var(--progress-bar-height);
    width: 100%;
    z-index: -1;
  }
}

.progress {
  background-color: var(--color-primary);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: var(--progress-bar-height);
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
}

.progressBar {
  background-color: var(--color-primary);
  height: 2px;
  left: 0;
  top: 6px;
  position: absolute;
}

.circle {
  background-color: var(--color-tint-1);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.4s 0.2s ease;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    inset: 1px;
    border: 1px solid var(--color-primary);
    background-color: var(--color-dark);
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.4s 0.2s ease;
    z-index: -1;
    transform-origin: center;
  }

  &.active,
  &.complete {
    background-color: var(--color-primary);

  }
  
  &.active::after {
    transform: scale(2.5);
  }
}

.btn {
  background-color: transparent;
  color: var(--color-tint-3);
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
  @include typography.font-style-small-caps;
  text-transform: uppercase;
  font-weight: var(--font-weight-6);

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    color: var(--color-tint-1);
    cursor: not-allowed;
  }
}
